<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedCategoryType"
                    :items="availableCategoryTypes"
                    item-text="name"
                    item-value="value"
                    label="分類"
                    :disabled="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                    clearable
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedCategoryType = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedCategoryId"
                    :items="availableCategories"
                    item-text="categoryName"
                    item-value="categoryId"
                    label="文章主類別"
                    :disabled="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                    clearable
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedCategoryId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedSubCategoryId"
                    :items="availableSubCategories"
                    item-text="categoryName"
                    item-value="categoryId"
                    label="文章子類別"
                    :disabled="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                    clearable
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedSubCategoryId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedReviewStatus"
                    :items="reviewStatusOptions"
                    item-text="name"
                    item-value="value"
                    label="審核狀態"
                    :disabled="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                    clearable
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedReviewStatus = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="
                        pagination.page = 1;
                        getDataFromApi();
                      "
                      :disabled="!isLoaded"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              文章列表
              <v-spacer></v-spacer>
              <!-- 需要後台filter -->
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                label="以文章標題和內容搜尋"
                single-line
                hide-details
                clearable
              ></v-text-field>
              <span class="pt-5" v-if="canEdit">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  :disabled="!isLoaded"
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn>
              </span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                item-key="id"
                :options.sync="pagination"
                :server-items-length="total"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                :show-expand="true"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>文章內容</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <div v-html="item.content"></div>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </td>
                </template>
                <template v-slot:item.imageUrl="{ item }">
                  <div class="pt-2 pb-2">
                    <v-img width="200" :src="item.imageUrl">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="black"
                            :size="30"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </template>
                <template v-slot:item.categoryType="{ item }">
                  <span v-if="item.categoryType === 'news'">最新消息</span>
                  <span v-else-if="item.categoryType === 'campaigns'">
                    熱門活動
                  </span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-if="canAudit && item.status === 'draft'">
                    <v-select
                      :items="reviewOptions"
                      v-model="item.status"
                      item-text="name"
                      item-value="value"
                      @change="onClickStatusChange(item)"
                    >
                    </v-select>
                  </span>
                  <span v-else>
                    {{ getStatus(item.status) }}
                  </span>
                </template>
                <template v-slot:item.isActive="{ item }">
                  <v-switch
                    v-model="item.isActive"
                    inset
                    color="green"
                    :false-value="0"
                    :true-value="1"
                    :disabled="!canAudit || item.status !== 'approved'"
                    @change="onToggleArticleStatus(item)"
                  ></v-switch>
                </template>
                <template v-slot:item.externalUrl="{ item }">
                  <a
                    v-if="item.externalUrl"
                    :href="item.externalUrl"
                    target="_blank"
                  >
                    {{ item.externalUrl }}
                  </a>
                </template>
                <template v-slot:item.startTs="{ item }">
                  <span>{{
                    item.startTs
                      ? moment.unix(item.startTs).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.endTs="{ item }">
                  <span>{{
                    item.endTs
                      ? moment.unix(item.endTs).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment.unix(item.createdAt).format("MM/DD/YYYY")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span>{{
                    item.updatedAt
                      ? moment.unix(item.updatedAt).format("MM/DD/YYYY HH:mm")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.actions="props">
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="cyan"
                        v-on="on"
                        @click="onEditItem(props.item)"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t(`common['Edit']`) }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.status === 'draft'
                            ? 'pink darken-2'
                            : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.status === 'draft'
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.status === 'draft'
                            ? onDeleteArticleClick(props.item.id)
                            : ''
                        "
                      >
                        <v-icon>fas fa-trash</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t(`common['Delete']`) }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="total"
                :loading="!isLoaded"
                @update="getDataFromApi"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="openEditDialog"
        persistent
        no-click-animation
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
            </span>
            <span class="text-h5"> 最新消息 </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.organizationId"
                      :label="$t(`budgets['Organization']`)"
                      :items="availableOrganizations"
                      item-text="organizationName"
                      item-value="organizationId"
                      :rules="rules.required"
                      :disabled="!permissionScope.organization || isProcessing"
                      @change="
                        getDepartmentList(editItem.organizationId);
                        editItem.departmentId = null;
                      "
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.departmentId"
                      :label="$t(`budgets['Department']`)"
                      :items="availableDepartments"
                      item-text="departmentName"
                      item-value="id"
                      :rules="rules.required"
                      :disabled="!permissionScope.department || isProcessing"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.categoryId"
                      label="文章主類別"
                      :items="availableCategories"
                      item-text="categoryName"
                      item-value="categoryId"
                      :rules="rules.required"
                      :disabled="isProcessing"
                      :clearable="true"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.subCategoryId"
                      label="文章子類別"
                      :items="availableSubCategories"
                      item-text="categoryName"
                      item-value="categoryId"
                      :rules="rules.required"
                      :disabled="isProcessing"
                      :clearable="true"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editItem.title"
                      type="text"
                      label="標題"
                      :rules="rules.required"
                      :disabled="isProcessing"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="showPicker1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editItem.startTime"
                          label="上架日期"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editItem.startTime"
                        color="blue-grey lighten-1"
                        @input="showPicker1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="showPicker2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editItem.endTime"
                          label="下架日期"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editItem.endTime"
                        color="blue-grey lighten-1"
                        @input="showPicker2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-row v-if="editItem.start">
                      <v-col cols="5">
                        <label class="text-sm text-body">上架日期</label>
                        <v-select
                          :items="months"
                          v-model="editItem.start.month"
                          label="Month"
                          item-text="name"
                          item-value="value"
                          color="#e91e63"
                          class="font-size-input input-style"
                          single-line
                          height="36"
                          :rules="rules.required"
                          :disabled="isProcessing"
                        >
                        </v-select>
                      </v-col>
                      <v-col sm="4" cols="3">
                        <v-select
                          :items="days"
                          v-model="editItem.start.day"
                          label="Day"
                          color="#e91e63"
                          class="font-size-input input-style mt-7"
                          single-line
                          height="36"
                          :rules="rules.required"
                          :disabled="isProcessing"
                        >
                        </v-select>
                      </v-col>
                      <v-col sm="3" cols="4">
                        <v-select
                          :items="years"
                          v-model="editItem.start.year"
                          label="Year"
                          color="#e91e63"
                          class="font-size-input input-style mt-7"
                          single-line
                          height="36"
                          :rules="rules.required"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row v-if="editItem.end">
                      <v-col cols="5">
                        <label class="text-sm text-body">下架日期</label>
                        <v-select
                          :items="months"
                          v-model="editItem.end.month"
                          label="Month"
                          item-text="name"
                          item-value="value"
                          color="#e91e63"
                          class="font-size-input input-style"
                          single-line
                          height="36"
                          :rules="rules.required"
                          :disabled="isProcessing"
                        >
                        </v-select>
                      </v-col>
                      <v-col sm="4" cols="3">
                        <v-select
                          :items="days"
                          v-model="editItem.end.day"
                          label="Day"
                          color="#e91e63"
                          class="font-size-input input-style mt-7"
                          single-line
                          height="36"
                          :rules="rules.required"
                          :disabled="isProcessing"
                        >
                        </v-select>
                      </v-col>
                      <v-col sm="3" cols="4">
                        <v-select
                          :items="years"
                          v-model="editItem.end.year"
                          label="Year"
                          color="#e91e63"
                          class="font-size-input input-style mt-7"
                          single-line
                          height="36"
                          :rules="rules.required"
                          :disabled="isProcessing"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col> -->
                </v-row>
                <v-col cols="12">
                  <v-file-input
                    accept="image/*"
                    label="圖片檔案"
                    v-model="uploadImg"
                    @change="onImageFileChange"
                    :disabled="isProcessing"
                  ></v-file-input>
                </v-col>

                <v-col v-if="uploadImgUrl || editItem.imageUrl" cols="12">
                  <v-img v-if="uploadImgUrl" width="200" :src="uploadImgUrl">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                          :size="30"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-img v-else width="200" :src="editItem.imageUrl">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                          :size="30"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-row>
                  <v-col>
                    <label class="text-body text-sm font-weight-normal ms-1">
                      文章內容
                    </label>
                    <html-editor
                      :value="editItem.content"
                      name="articleEditor"
                      @input="onInputChange"
                      :disabled="isProcessing"
                    ></html-editor>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeEditDialog"
              :disabled="isProcessing"
            >
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onSaveEditItem"
              :disabled="isProcessing"
              :loading="isProcessing"
            >
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchArticles,
  fetchOrganizations,
  fetchDepartments,
  fetchArticleCategories,
  fetchArticleSubCategories,
} from "src/apis/fetchData";
import { formatNumber, checkPermission } from "src/util/utils";
// import {
//   generateArrayOfYears,
//   generateArrayOfMonths,
//   generateArrayOfDays,
// } from "src/util/utils";
import {
  uploadImage,
  updateArticle,
  approveArticle,
  activeArticle,
  deactiveArticle,
} from "src/apis/updateData";
import { removeArticle } from "src/apis/deleteData";

import HtmlEditor from "./Widgets/HtmlEditor.vue";
import Pagination from "../Campaigns/Widgets/Pagination.vue";

export default {
  name: "Articles",
  components: { HtmlEditor, Pagination },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      reviewStatusOptions: [
        {
          name: "審核通過",
          value: "approved",
        },
        {
          name: "審核駁回",
          value: "rejected",
        },
      ],
      selectedReviewStatus: null,
      showPicker1: false,
      showPicker2: false,
      isProcessing: false,
      isLoaded: true,
      isNew: false,
      viewOnly: false,
      openEditDialog: false,
      singleExpand: true,
      expanded: [],
      showExpand: true,
      total: 0,
      tableData: [],
      tableHeaders: [
        {
          text: "ID",
          sortable: true,
          value: "id",
        },
        {
          text: "單位",
          sortable: false,
          value: "departmentName",
        },
        {
          text: "分類",
          sortable: false,
          value: "categoryType",
        },
        {
          text: "主類別",
          sortable: false,
          value: "categoryName",
        },
        {
          text: "子類別",
          sortable: false,
          value: "subCategoryName",
        },
        {
          text: "標題",
          sortable: false,
          value: "title",
        },
        {
          text: "圖片",
          sortable: false,
          value: "imageUrl",
        },
        {
          text: "狀態",
          sortable: false,
          value: "status",
        },

        // {
        //   text: "內文",
        //   sortable: false,
        //   value: "content",
        // },
        {
          text: "上架時間",
          sortable: true,
          filterable: false,
          value: "startTs",
        },
        {
          text: "下架時間",
          sortable: true,
          filterable: false,
          value: "endTs",
        },
        {
          text: "啟用",
          sortable: false,
          value: "isActive",
        },
        // {
        //   text: "建立時間",
        //   sortable: false,
        //   filterable: false,
        //   value: "createdAt",
        // },
        {
          text: "更新時間",
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },

        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      // years: [],
      // months: [],
      // days: [],
      pagination: {
        itemsPerPage: 10,
      },
      debounce: null,
      defaultEditItem: {
        imageUrl: null,
        organizationId: null,
        departmentId: null,
        start: {},
        end: {},
      },
      editItem: {
        imageUrl: null,
        organizationId: null,
        departmentId: null,
      },
      uploadImg: null,
      uploadImgUrl: null,
      availableDepartments: [],
      availableCategories: [],
      availableOrganizations: [],
      availableSubCategories: [],
      content: "",
      reviewOptions: [
        {
          name: "草稿",
          value: "draft",
        },
        {
          name: "審核通過",
          value: "approved",
        },
        {
          name: "審核駁回",
          value: "rejected",
        },
      ],
      selectedSubCategoryId: null,
      selectedCategoryId: null,
      selectedCategoryType: null,
      availableCategoryTypes: [
        {
          value: "news",
          name: "最新消息",
        },
        {
          value: "campaigns",
          name: "熱門活動",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
      permissionScope: (state) => state.permissionScope,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:ArticleManage",
          "EditArticles",
          true
        );
      }
      return false;
    },
    canAudit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:ArticleManage",
          "AuditArticles",
          true
        );
      }
      return false;
    },
  },
  watch: {
    // pagination: {
    //   handler() {
    //     this.getDataFromApi();
    //   },
    //   deep: true,
    // },
    search: {
      handler() {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(function () {
          self.getDataFromApi();
        }, 200);
      },
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
    // this.years = generateArrayOfYears();
    // this.months = generateArrayOfMonths();
    // this.days = generateArrayOfDays();
  },
  methods: {
    init() {
      this.getDataFromApi();
      // this.getDepartmentList();
      this.getOrganizations();
      this.getArticleCategories();
      this.getArticleSubCategories();
    },
    getStatus(s) {
      let parsedType = this.reviewOptions.filter((t) => t.value === s);
      return parsedType.length ? parsedType[0].name : "Unknown";
    },
    getOrganizations() {
      fetchOrganizations(this.merchantId, null)
        .then((res) => {
          console.log("fetchOrganizations done", res);
          this.availableOrganizations = [...res.data];
        })
        .catch((e) => {
          console.log("fetchOrganizations failed", e);
          this.$swal({
            text: "無法取得機關資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    getDepartmentList(organizationId) {
      // const merchantId = 1;
      let query = {
        organizationId: organizationId,
      };
      console.log("getDepartmentList:" + JSON.stringify(query));

      fetchDepartments(this.merchantId, query)
        .then((res) => {
          console.log("fetchDepartments done", res);
          this.availableDepartments = [...res.data];
        })
        .catch((e) => {
          console.log("fetchDepartments failed", e);
          this.$swal({
            text: "無法取得單位資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    getArticleCategories() {
      fetchArticleCategories(this.merchantId, null)
        .then((res) => {
          console.log("fetchArticleCategories done", res);
          this.availableCategories = [...res.data];
        })
        .catch((e) => {
          console.log("fetchArticleCategories failed", e);
          this.$swal({
            text: "無法取得文章主類別資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    getArticleSubCategories() {
      fetchArticleSubCategories(this.merchantId, null)
        .then((res) => {
          console.log("fetchArticleSubCategories done", res);
          this.availableSubCategories = [...res.data];
        })
        .catch((e) => {
          console.log("fetchArticleSubCategories failed", e);
          this.$swal({
            text: "無法取得文章子類別資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    getDataFromApi() {
      this.isLoaded = false;
      this.getArticles()
        .then((data) => {
          this.tableData = [...data.items];
          this.total = data.total;
          this.isLoaded = true;
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    getArticles() {
      this.tableData = [];
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        // console.log("pagination", this.pagination);
        let query = {
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
          sortBy: sortBy && sortBy.length ? sortBy[0] : null,
          desc: sortDesc,
          search: this.search ? this.search.trim() : null,
          categoryId: this.selectedCategoryId,
          subCategoryId: this.selectedSubCategoryId,
          categoryType: this.selectedCategoryType,
          status: this.selectedReviewStatus,
        };
        let items = [];
        let total = 0;
        // sortby/search 參數是否要丟後端處理
        fetchArticles(this.merchantId, query)
          .then((res) => {
            items = [...res.data.articles];
            total = res.data.total;
          })
          .catch((e) => {
            console.log("fetchArticles failed", e);
            this.$swal({
              text: "無法取得文章資料",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 1000);
          });
      });
    },
    onInputChange(data) {
      this.content = data;
    },
    closeEditDialog() {
      this.$refs.editForm.reset();
      this.$refs.editForm.resetValidation();
      this.openEditDialog = false;
    },
    onEditItem(item) {
      this.isProcessing = false;
      let start = null;
      let end = null;
      if (item) {
        this.editItem = Object.assign({}, item);
        start = moment.unix(item.startTs);
        end = moment.unix(item.endTs);
        // this.editItem.start = {
        //   year: start.format("YYYY"),
        //   month: start.format("MM"),
        //   day: start.format("DD"),
        // };
        // this.editItem.end = {
        //   year: end.format("YYYY"),
        //   month: end.format("MM"),
        //   day: end.format("DD"),
        // };
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.editItem.organizationId = this.organizationId;
        if (!this.permissionScope.department) {
          this.editItem.departmentId = this.departmentId;
        }
        start = moment();
        end = moment().add(1, "months");
        // this.editItem.start = {
        //   year: start.format("YYYY"),
        //   month: start.format("MM"),
        //   day: start.format("DD"),
        // };
        // this.editItem.end = {
        //   year: end.format("YYYY"),
        //   month: end.format("MM"),
        //   day: end.format("DD"),
        // };
        this.isNew = true;
      }
      this.getDepartmentList(this.editItem.organizationId);
      this.editItem.startTime = start.format("YYYY-MM-DD");
      this.editItem.endTime = end.format("YYYY-MM-DD");
      this.openEditDialog = true;
    },
    onSaveEditItem() {
      this.isProcessing = true;

      const valid = this.$refs.editForm.validate();
      if (valid) {
        // const start =
        //   this.editItem.start.year.toString() +
        //   "-" +
        //   this.editItem.start.month.toString() +
        //   "-" +
        //   this.editItem.start.day.toString() +
        //   " 00:00:00";

        // const end =
        //   this.editItem.end.year.toString() +
        //   "-" +
        //   this.editItem.end.month.toString() +
        //   "-" +
        //   this.editItem.end.day.toString() +
        //   " 23:59:59";
        const start = this.editItem.startTime + " 00:00:00";
        const end = this.editItem.endTime + " 23:59:59";
        this.editItem.startTs = moment(start).unix();
        this.editItem.endTs = moment(end).unix();
        console.log(this.editItem);
        const diff = moment(start).diff(moment(end));
        if (diff >= 0) {
          this.$swal({
            text: "上架時間不得小於下架時間",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          this.isProcessing = false;
          return;
        }
        if (this.uploadImg) {
          // Need to replace upload image, add get new file name for save db
          let data = new FormData();
          data.append("file", this.uploadImg);

          uploadImage(this.merchantId, data)
            .then((result) => {
              console.log("upload image done:" + JSON.stringify(result));
              this.editItem.imageUrl = result.data;
              setTimeout(this.updateArticle);
            })
            .catch((e) => {
              console.log("uploadImage failed", e);
              let errmsg = "上傳檔案失敗";
              if (e.response && e.response.data && e.response.data.message) {
                errmsg += "<br/>" + e.response.data.message;
              }
              this.isProcessing = false;
              this.showErrorAlert(errmsg);
            });
        } else {
          this.updateArticle();
        }
      } else {
        this.isProcessing = false;
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    updateArticle() {
      this.editItem.content = this.content
        ? this.content
        : this.editItem.content;
      updateArticle(this.merchantId, this.editItem)
        .then((result) => {
          console.log(result);
          let self = this;
          setTimeout(function () {
            self.getDataFromApi();
            self.$refs.editForm.resetValidation();
            self.$refs.editForm.reset();
            self.openEditDialog = false;
            this.isProcessing = false;
          }, 10);
        })
        .catch((e) => {
          console.log(e);
          console.log("updateArticle failed", e);
          let errmsg = this.isNew ? "新增失敗" : "更新失敗";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showErrorAlert(errmsg);
          this.isProcessing = false;
        })
        .finally(() => {});
    },
    onImageFileChange() {
      if (this.uploadImg) {
        this.uploadImgUrl = URL.createObjectURL(this.uploadImg);
      } else {
        this.uploadImgUrl = null;
      }
    },
    onDeleteArticleClick(id) {
      this.$swal({
        title: `刪除文章`,
        html: `是否確定刪除文章？<br/>刪除後將無法復原`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        cancelButtonText: this.$i18n.t(`common["Cancel"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((res) => {
        console.log(res);
        if (res.isConfirmed) {
          this.deleteArticle(id);
        }
      });
    },
    deleteArticle(id) {
      removeArticle(this.merchantId, id)
        .then((res) => {
          console.log("removeArticle done: ", res);
          if (res) {
            this.getDataFromApi();
            this.$swal({
              title: "刪除成功",
              html: `已成功刪除文章`,
              type: "success",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          } else {
            throw new Error("Error");
          }
        })
        .catch((e) => {
          console.log("removeArticle failed", e);
          let errmsg = "無法刪除文章";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          const title = "刪除失敗";
          this.showErrorAlert(errmsg, title);
        });
    },
    showErrorAlert(message, inputTitle) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: inputTitle || title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onClickStatusChange(item) {
      approveArticle(this.merchantId, item)
        .then(() => {
          this.getDataFromApi();
        })
        .catch((e) => {
          console.log(e);
          console.log("approveArticle failed", e);
          let errmsg = "審核失敗";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showErrorAlert(errmsg);
        })
        .finally(() => {});
    },
    onToggleArticleStatus(item) {
      let toggle = deactiveArticle;
      if (item.isActive) {
        toggle = activeArticle;
      }

      toggle(this.merchantId, item.id, null)
        .then(() => {
          console.log("done toggle article status.");
        })
        .catch((e) => {
          console.log("change article status failed:", e);
          this.$swal({
            title: "",
            html: "變更文章狀態失敗",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          return;
        });
    },
  },
};
</script>
<style scoped></style>
