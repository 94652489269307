<template>
  <div class="quill mt-3">
    <div :id="toolbarId">
      <div class="ql-formats">
        <!-- <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-link"></button>
        <button class="ql-blockquote"></button>
        <button class="ql-code"></button> -->
        <!-- <button class="ql-image"></button> -->
        <!-- <button type="button" class="ql-list" value="ordered"></button>
        <button type="button" class="ql-list" value="bullet"></button> -->
      </div>
    </div>
    <div :id="editorId" :name="name" class="" ref="editor"></div>
  </div>
</template>
<script>
import "quill/dist/quill.snow.css";
export default {
  name: "html-editor",
  props: {
    value: {
      type: String,
      default: "",
    },
    name: String,
  },
  data() {
    return {
      editor: null,
      content: null,
      lastHtmlValue: "",
      editorId: null,
      toolbarId: null,
    };
  },
  methods: {
    initialize(Quill) {
      this.editor = new Quill(`#${this.editorId}`, {
        theme: "snow",
        modules: {
          // toolbar: `#${this.toolbarId}`,
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜體 下劃線 刪除線 -----['bold', 'italic', 'underline', 'strike']
            ["blockquote", "code-block"], // 引用  代碼塊-----['blockquote', 'code-block']
            [{ header: 1 }, { header: 2 }], // 1、2 級標題-----[{ header: 1 }, { header: 2 }]
            [{ list: "ordered" }, { list: "bullet" }], // 有序、無序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
            // [{ script: "sub" }, { script: "super" }], // 上標/下標-----[{ script: 'sub' }, { script: 'super' }]
            [{ indent: "-1" }, { indent: "+1" }], // 縮進-----[{ indent: '-1' }, { indent: '+1' }]
            // [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
            [{ size: ["small", false, "large", "huge"] }], // 字體大小-----[{ size: ['small', false, 'large', 'huge'] }]
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 標題-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
            [{ color: [] }, { background: [] }], // 字體顏色、字體背景顏色-----[{ color: [] }, { background: [] }]
            // [{ font: [] }], // 字體種類-----[{ font: [] }]
            // [{ align: [] }], // 對齊方式-----[{ align: [] }]
            ["link"],
            ["clean"], // 清除文本格式-----['clean']
            // ["image", "video"], // 鏈接、圖片、視頻-----['link', 'image', 'video']],
          ],
        },
      });
      if (this.value.length > 0) {
        this.editor.pasteHTML(this.value);
      }
      let editorRef = this.$refs.editor;
      let node = editorRef.children[0];
      this.editor.on("text-change", () => {
        let html = node.innerHTML;
        if (html === "<p><br></p>") {
          html = "";
        }
        this.content = html;
        this.$emit("input", this.content);
      });
    },
    pasteHTML() {
      if (!this.editor) {
        return;
      }
      this.editor.pasteHTML(this.value);
    },
    randomString() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  async mounted() {
    let Quill = await import("quill");
    Quill = Quill.default || Quill;
    this.editorId = this.randomString();
    this.toolbarId = this.randomString();
    this.$nextTick(() => {
      this.initialize(Quill);
    });
  },
  watch: {
    value(newVal) {
      if (newVal !== this.content) {
        this.pasteHTML(newVal);
      }
    },
  },
};
</script>
