// import axios from "axios";

const version = "v1";

export function removeBanner(merchantId, bannerId) {
  var config = {
    method: "delete",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banners/${bannerId}`,
  };

  return window.qcsso.request(config);
}

export function removeArticle(merchantId, articleId) {
  var config = {
    method: "delete",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/articles/${articleId}`,
  };

  return window.qcsso.request(config);
}

export function removeArticleCategory(merchantId, categoryId) {
  var config = {
    method: "delete",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/article-categories/${categoryId}`,
  };

  return window.qcsso.request(config);
}

export function removeArticleSubCategory(merchantId, categoryId) {
  var config = {
    method: "delete",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/article-subcategories/${categoryId}`,
  };

  return window.qcsso.request(config);
}
